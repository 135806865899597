@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=Inter:wght@400&display=swap');

.navbar {

    font-family: 'Montserrat', sans-serif;
    background: #F5F5F5;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0% 6%;
    
    
    

}





.navbar img {
    width: 140px;
    
}

.navbar a {
    float: right;
    display: block;
    color: #1c1b1b;
    text-align: center;
    padding: 1.4%;
    text-decoration: none;
    font-size: 16px;
    transition: 0.5s;
    
    margin-top: 1%;
}

.navbar a:hover {

    color: #EC6B2A;
}

.navbar a.active {
    background-color: #04AA6D;
    color: white;
}

.navbar .icon {
    display: none;
}



  
  
 

@media screen and (max-width: 480px) {
    .navbar img{
        width: 80px;
    }
}

@media screen and (max-width: 768px) {
    .navbar.responsive {
        position: relative;
        
    }

    .navbar.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .navbar.responsive a {
        float: none;
        display: block;
        text-align: left;
    }

    .navbar a {
        display: none;
    }

    .navbar a.icon {
        float: right;
        display: block;
    }
}