/* Reset some default styles */
body, h1, h2, h3, p, ul, img {
    margin: 0;
    padding: 0;
  }
  
  /* Set a modern background color for the entire component */
  .microsilica {
    font-family: 'Montserrat', sans-serif;
    background-color: #f4f4f4;
    font-weight: 500;
    
  }
  
  /* Header Section */
  .microsilica_head {
    position: relative;
    overflow: hidden;
    
  }
  
  .microsilica_head img {
    width: 100%;
    max-height: 300px;
    aspect-ratio: 3/2;
  }
  
  .about_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2em;
    font-weight: bold;
  }
  
  /* Main Content Sections */
  .micro-1 {
    font-weight: 500;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding:5% 12% ;
    font-size: 13px;
  }
  
  /* Subsection Titles */
  .micro-1 h2 {
    color: #EC6B2A;
    margin-top: 20px;
    font-weight: 600;
  }
  
  /* Text Content */
  .micro-1 p,
  .micro-1 ul {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  /* Lists */
  .micro-1 ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Images */
  .micro-1 img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .micro-1 {
      margin-top: 20px;
    }
  }
  