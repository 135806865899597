/* Reset some default styles */
body, h1, h2, h3, p, ul {
    margin: 0;
    padding: 0;
  }
  
  /* Set a modern background color for the entire component */
  .Drillingpolymerpage {
    font-family: 'Montserrat', sans-serif;
    background-color: #f4f4f4;
    
  }
  
  /* Header Section */
  .drilling_polymer_head {
    position: relative;
    overflow: hidden;
    
  }
  
  .drilling_polymer_head img {
    width: 100%;
    max-height: 300px;
    aspect-ratio: 3/2;
  }
  
  .about_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2em;
    font-weight: bold;
  }
  
  /* Main Content Sections */
  .drilling {
    
    
    font-size: 15px;
    padding: 5% 12%;
  }
  
  /* Subsections */
  .drilling-1,
  .drilling-2,
  .drilling-3,
  .drilling-4,
  .drilling-5 {
    margin-bottom: 30px;
  }
  
  /* Subsection Titles */
  .drilling h2,
  .drilling-2 h2,
  .drilling-3 h2,
  .drilling-4 h2,
  .drilling-5 h2 {
    color: #333;
    text-align: center;
    padding: 2%;
    font-size: 30px;
  }

  
  .drilling-2 h3
  {
    text-align: center;
    font-size: 25px;
  }
  .drilling-4-4 h3{
    font-size: 25px;
  }
  
  /* Text Content */
  .drilling p,
  .drilling-1 p {
    line-height: 1.6;
  }
  
  /* Lists */
  .drilling ul,
  .drilling-2-left ul,
  .drilling-2-right ul,
  .drilling-4-4 ul,
  .drilling-5 ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Horizontal layout for sections with left and right content */
  .drilling-2 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
  }

  .drilling-4-4{
    padding: 1%;
    margin-bottom: 10px;
    border-left:8px solid #333;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .drilling-2 {
      flex-direction: column;
    }
  }

  @media (max-width: 480px){
    .drilling{
      font-size: 13px;
    }
  }
  