/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

/* Set a modern background color for the entire component */
.Bentonite {
  font-family: 'Montserrat', sans-serif;


}

/* Header Section */
.bentonite_head {
  position: relative;
  overflow: hidden;

}

.bentonite_head img {
  width: 100%;
  max-height: 300px;
  aspect-ratio: 3/2;
}

.about_head_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2em;
  font-weight: bold;
}

/* Main Content Sections */

.bentonite_content {
  padding: 5% 12%;
  
}

.bentonite-1,
.bentonite-2,
.bentonite-3,
.bentonite-4 {


  font-size: 15px;
  padding: 0 3%;

}

.bentonite-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 3%;
}

h2,
h3 {
  color: #EC6B2A;
  
}

/* Subsections */
.bentonite-2-1 {
  padding: 10px;
}

.bentonite-3 {
  margin-bottom: 3%;
}

.bentonite-4-1-left ,
.bentonite-4-2-left ,
.bentonite-4-3-left ,
.bentonite-4-4-left{
  width: 50%;
}

.bentonite-4-1-right,
.bentonite-4-2-right ,
.bentonite-4-3-right ,
.bentonite-4-4-right{
  width: 50%;
}

/* Images */
.bentonite-4-2-left img,
.bentonite-4-3-right img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Lists */
.bentonite-3 ul,
.bentonite-4-1-right ul {
  list-style-type: disc;
  margin-left: 30px;
}

.bentonite-4-1-right ul ul {
  list-style-type: circle;
  margin-left: 20px;
}

/* Horizontal layout for sections with left and right content */
.bentonite-4-1,
.bentonite-4-2,
.bentonite-4-3,
.bentonite-4-4 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  
  margin-bottom: 30px;
}

/* Responsive Layout */
@media (max-width: 850px) {

  .bentonite-4-1,
  .bentonite-4-4 {
    flex-direction: column;
    align-items: center;
  }

  .bentonite-4-2-left img,
  .bentonite-4-3-right img {
    margin-top: 20px;
  }

  .bentonite-4-1-left,
  .bentonite-4-1-right,
  .bentonite-4-4-left,
  .bentonite-4-4-right{
     width: 100%; 
  }

}

@media (max-width: 480px){
  .bentonite-4-2,
  .bentonite-4-3{
    flex-direction: column;
  }

  .bentonite-4-2-left,
  .bentonite-4-2-right,
  .bentonite-4-3-left,
  .bentonite-4-3-right{
    width: 100%;
  }
}
