.Projects{
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
}

.Projects h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: 800;
    color: #EC6B2A;
}

.projectcard {
    text-align: center;
}

.projectcard p{
  font-size: 20px;
}

.projectcard img{
    width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  max-width: 100%;
  border-radius: 8px;
}

.all_projects{
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 5%;
  

}