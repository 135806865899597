.homepage {
  overflow-x: hidden;


}

.homepage div img {
  width: 100%;
  height: 100vh;
}

.slider-item {
  position: relative;


}



.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* Adjust the color as needed */
  font-size: 24px;
  /* Adjust the font size as needed */
  z-index: 3;
  width: 90%;
  padding: 2%;

}

.slider-text p {
  width: 70%;
  padding: 1%;
}


.visionpage {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: -3%;
  flex-wrap: wrap;
}

.visions {
  width: 40%;
  border-radius: 20px;
  background: white;
  z-index: 2;
  padding: 3%;
  font-size: 18px;

}

.visions div {
  display: flex;

}

.values div {
  display: flex;
}

.values div img {
  width: 50px;
}

.visions div img {
  width: 50px;
}

.visions h1 {

  color: #EC6B2A;
}

.values h1 {
  color: #EC6B2A;
}

.values {
  width: 40%;
  border-radius: 20px;
  background: white;
  z-index: 2;
  padding: 3%;
  font-size: 18px;

}

.productspage {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 3%;
  background-image: url("https://img.freepik.com/free-photo/black-rough-background_1194-7591.jpg");
  
}

.product_heading {
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #EC6B2A;
  padding: 2%;
}

.product_section {
  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}




.productitem {
  
  width: 500px;
  padding: 10px;
  text-align: center;




}


.productitem a:hover img {
  transform: scale(1.03);
}

.productitem img {
  aspect-ratio: 3/1;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  transition: all 0.3s ease;
}



.projectspage {
  display: flex;
  width: 100%;
  padding: 2%;
  align-items: center;
  justify-content: center;




}

.projectpage_left {
  width: 45%;


}

.projectpage_left img {

  width: 100%;

}

.projectpage_right {

  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 23px;
}

.projectbtn {
  display: flex;
  justify-content: flex-end;
  width: 100%;

}

.projectbtn button {
  color: white;
  background-color: #EC6B2A;
  height: 40px;
  width: 120px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border: none;
  transition: all 0.5s ease;
}

.projectbtn button:hover {
  background-color: white;
  color: #EC6B2A;
  border: 3px solid #EC6B2A;
}

.clientspage {
  overflow-x: hidden;
  margin: 2%;
}

.approvedpage {
  overflow-x: hidden;
  margin: 2%;

}

.clientspage_heading {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #EC6B2A;
  padding-bottom: 4%;
}

.clientspage_carousel div img {
  width: 130px;
  height: 100px;
}

#contact {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  padding: 4%;
  background: #E8E8E8;

}

.contactform {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
  gap: 20px;
  background: #fff;
}

.contactform h1 {
  color: #EC6B2A;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
}

.contactform form {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

.contactform form input {
  height: 35px;
  /* border-radius: 15px; */
  border: #EC6B2A;
  border-bottom: 2px solid #EC6B2A;
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
  padding: 1.5% 0%;
  color: black;
  outline: none;
}

.contactform form button {
  color: white;
  background-color: #EC6B2A;
  height: 40px;
  width: 120px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border: none;
  transition: all 0.5s ease;
}

.contactform form button:hover {
  background-color: white;
  color: #EC6B2A;
  border: 3px solid #EC6B2A;
}

#brochure_btn{
  color: white;
  background-color: #EC6B2A;
  height: 40px;
  width: 200px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border: none;
  transition: all 0.5s ease;
  text-decoration: none;
  text-align: center;
  padding: 5% 0;
}

#brochure_btn:hover{
  background-color: white;
  color: #EC6B2A;
  border: 1px solid #EC6B2A;
}


/* Your existing CSS */

/* Responsive Layout */

@media only screen and (max-width: 850px){
  .productitem{
    width: 370px;
  }
  .projectpage_right{
    font-size: 18px;
    padding: 2%;

  }
}

@media only screen and (max-width: 768px) {
  .slider-text {
    width: 80%;
  }

  .homepage div img {

    height: 70vh;
  }


  .slider-text h1 {
    font-size: 35px;
  }

  .slider-text p {
    width: 80%;
  }

  .visionpage {
    flex-direction: column;
    align-items: center;
  }

  .visions,
  .values {
    width: 80%;
    margin-bottom: 20px;
    padding: 3%;
  }

  .values h1,
  .visions h1 {
    font-size: 22px;
  }

  .product_heading {
    font-size: 35px;
  }

  .productitem {
    width: 80%;
    transform: all 0.3s ease;
  }





  .projectspage {
    flex-direction: column;
    align-items: center;
  }

  .projectpage_left,
  .projectpage_right {
    width: 65%;
  }

  .projectpage_right{
    padding: 2%;
    font-size: 18px;
  }

  .projectpage_left img {
    height: auto;
  }

  .projectbtn button {
    height: 30px;
    width: 100px;
  }

  .clientspage_carousel div img {
    width: 100px;
    height: 75px;
  }

  #contact {
    padding: 2%;
  }

  .contactform {
    width: 50%;
  }

  .contactform h1 {
    font-size: 24px;
  }

  .contactform form {
    width: 100%;
  }

  .contactform form input {
    width: 80%;
  }

  .contactform form button {
    width: 100px;
  }
}



@media only screen and (max-width: 480px) {
  .homepage div img {

    height: 50vh;
  }
  .slider-text{
    top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;

  }

  .product_heading{
    font-size: 23px;
  }

  .clientspage_carousel div img {
    width: 75px;
    height: 40px;
  }

  .projectpage_right{
    font-size: 14px;
  }


}