@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.about_page{
    font-family: 'Montserrat', sans-serif;
}

.about_head {
    position: relative;
    text-align: center;
    color: white;
}

.about_head img{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    

}

.about_head::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5 for 50% darkness) */
}

.about_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    
}

.about_head_text::after {
    content: "";
    display: block;
    width: 70px;
    height: 7px; 
    background-color: #EC6B2A; 
    margin-top: 5px; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    
}

.about_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;
    width: 100%;
    
    padding: 1%;
    text-align: center;
}

.about_content_top{
    width: 85%;
    padding: 2%;
    
}

.about_content_top span{
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 2rem;
    color: #EC6B2A;
}

.about_content_bottom{
    width: 85%;
    display: flex;
    padding: 2%;
    
    
}






/* Responsive Layout */

@media only screen and (max-width : 900px){
    .about_content {
        padding: 8%;
        font-size: 16px;
      }
}

@media only screen and (max-width: 768px) {
    .about_head_text {
      font-size: 2rem;
    }
  
    .about_content {
      padding: 10%;
    }
  
    .about_content_top {
      width: 100%;
    }
  
    .about_content_bottom {
      flex-direction: column;
    }
    
  
    
  }

  @media only screen and (max-width: 480px){
    .about_content{
      font-size: 13px;
    }
    .about_content_mid h2{
      font-size: 10px;
    }

  }
  
  .about_content_mid{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about_content_mid h2{
    text-align: center;
    padding: 2%;
    font-size: 32px;
  }
  
  .about_content_boxes{
    display: flex;
    gap: 20px;
    font-weight: 600;
    color: #F5F5F5;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    
    
  }



  .blue{
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #296998;
    width: 170px;
  }

  .orange{
    width: 170px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #EC6B2A;
  }