/* Reset some default styles */
body, h1, h2, h3, p, ul {
    margin: 0;
    padding: 0;
  }
  
  /* Set a modern background color for the entire component */
  .hydratedlime {
    font-family: 'Montserrat' , sans-serif;
    
    
  }
  
  /* Header Section */
  .hydratedlime_head {
    position: relative;
    overflow: hidden;
    
  }
  
  .hydratedlime_head img {
    width: 100%;
    max-height: 300px;
    aspect-ratio: 3/2;
  }
  
  .about_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2em;
    font-weight: bold;
  }
  
  /* Main Content Sections */
  .hydrated-1{
    padding: 5% 13%;
    font-size: 15px;
  }

  
  .hydrated-1-1,
  .hydrated-1-2
   {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    
  }
  
  

  /* Subsection Titles */
  .hydrated-1 h1,
  .hydrated-1-1-left h2,
  .hydrated-1-2-right h3,
  .hydrated-1-3 h2 {
    color: #EC6B2A;
   
  }

  .hydrated-1-2-right h3{
    padding: 3%;
    font-size: 20px;
  }

  .hydrated-1-1-left h2{
    padding: 3% ;
  }
  
  /* Text Content */
  .hydrated-1 p,
  .hydrated-1-1-left ul,
  .hydrated-1-2-right ul,
  .hydrated-1-3 ul {
    line-height: 1.6;
    padding: 1% 0;
  }
  
  /* Lists */
  .hydrated-1-1-left ul,
  .hydrated-1-2-right ul
   {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Horizontal layout for sections with left and right content */
  .hydrated-1-1,
  .hydrated-1-2,
  .hydrated-1-3 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
    padding: 2%;
  }
  
  /* Images */
  .hydrated-1-1-right img,
  .hydrated-1-2-left img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .hydrated-1-1,
    .hydrated-1-2,
    .hydrated-1-3 {
      flex-direction: column;
      
    }
    .hydrated-1{
      font-size: 13px;
    }
  }

  @media only screen and (max-width : 480){
    .hydrated-1{
      font-size: 11px;
    }
  }
  