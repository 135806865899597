/* Your existing styles here */

.productnav {
    display: flex;
    justify-content: space-around;
    background-color: #333; /* Set your desired background color */
    padding: 1%;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    

    
    
}

.productnav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    
}

.productnav li {
    margin: 0; /* Adjust spacing between menu items */
}

.productnav a {
    text-decoration: none;
    color: white; /* Set your desired text color */
    position: relative;
    padding: 10px; /* Add padding for better touch/click experience */
}

.productnav a::after {
    content: '';
    display: block;
    height: 5px;
    width: 0;
    background-color: #EC6B2A; /* Set your desired hover line color */
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s; /* Adjust the transition duration */
}

.productnav a:hover::after, .productnav a.active::after {
    width: 100%;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .productnav {
        display: none;
    }
}
