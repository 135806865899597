.footer{
    display: flex;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #F5F5F5;
    
    
}

.footer_logo{
    min-height: 100px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_logo img{
    
    width: 50%;
}

.footer_licenses{
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_licenses img{
    
    width: 30%;
    font-size: x-small;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1%;
}

.footer_address{
    min-height: 100px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer_address p{
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright{
    background: #EC6B2A;
    color: #F5F5F5;
    text-align: center;
    width: 100%;
    
}

/* ... (Your existing CSS) ... */

/* Responsive Layout */
@media only screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
  
    .footer_logo,
    .footer_licenses,
    .footer_address {
      width: 100%;
      text-align: center;
    }
  
    .footer_logo img {
      width: 40%; /* Adjust as needed */
    }
  
    .footer_licenses img {
      width: 40%; /* Adjust as needed */
    }
  
    .footer_address {
      margin-top: 10px; /* Adjust as needed */
    }
  }

  
  